import React from "react"
import { Location } from "@reach/router"
import { LocalizationStore } from "./src/context/LocalizationContext"

export const wrapRootElement = ({ element }) => (
  <Location>
    {(locationProps) => (
      <LocalizationStore {...locationProps}>{element}</LocalizationStore>
    )}
  </Location>
)

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`)
